.goal {
    width: 90%;
    min-height: 150px;
    padding: 0 0 0 1em;
}

.goal.complete {
    text-decoration: line-through;
    opacity: 0.7;
}

.goal-buttons {
    display: flex;
    justify-content: flex-end;
}

.goal-button {
    border: 0;
    background-color: transparent;
}

.goal-button:hover {
    cursor: pointer;
}

.goal-button:first-of-type svg {
    color: darkgreen;
}

.goal-button:first-of-type svg:hover {
    color: forestgreen;
}

.goal-button:last-of-type svg {
    color: darkred;
}

.goal-button:last-of-type svg:hover {
    color: red;
}

.goal-text {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
}