.new-goal {
    padding: 2em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
}

.new-goal-form{ 
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
}

.goal-input {
    width: 100%;
    height: 2em;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;
    border-radius: 0;
    text-align: center;
    color: white;
    font-size: 1em;
}

.goal-input::placeholder {
    color: white;
}

.goal-input:focus {
    border: none;
}

.goal-submit {
    height: 2em;
    width: 30%;
    border: none;
}

.goal-submit:hover {
    cursor: pointer;
    background-color: gainsboro;
}