.background-image {
  background-color: black;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0;
  min-height: 100vh;
}

.App {
  width: 100vw;
  height: fit-content;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 0;
  color: white;
  padding: 0 10%;
  text-align: center;
  gap: 20px;
  padding-top: 4em;
  backdrop-filter: blur(3px);
}

.panel {
  width: 100%;
  /* height: 90%; */
  margin: 0 auto;
  /* gap: 10px; */
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  text-shadow: 2px 2px 3px black;
}

.scroll-buttons {
  position: fixed;
  top: 50vh;
  width: 100vw;
  height: 30px;
  display: flex;
  justify-content: space-between;
  padding: 0 0.2em;
}

.arrow {
  height: 100%;
  width: auto;
}

@media only screen and (min-width: 750px) {
  .App {
    padding-top: 2em;
  }
  .scroll-buttons {
    height: 50px;
    padding: 0 2em;
  }
}