.weather {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    padding-right: 1em;
}

.temp {
    display: flex;
    flex-direction: column;
}

.temp p:last-of-type {
    font-size: 0.5em;
}

@media only screen and (min-width: 750px) {
    .weather {
        width: 300px;
      }
}