.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
}

@media only screen and (min-width: 750px) {
    .header {
        flex-direction: row;
      }
}