.goals {
    height: 400px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 1em;
    overflow-y: scroll;
}

.completed-count {
    align-self: center;
    position: sticky;
    bottom: 0.1em;
}